import featureFlagConfigs from 'configs/featureFlagConfigs';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { manageTranslationService } from 'services/manageTranslationsService';
import { useFreshdeskContextState } from './freshdesk-context';

export const ManageTranslationsStateContext = new createContext([]);
export const ManageTranslationsSetterContext = new createContext();

export const ManageTranslationsContextProvider = ({ children }) => {
  const [translatedData, setTranslatedData] = useState([]);
  const { getUserDetails, getAccountPrimaryLanguage } = useFreshdeskContextState();
  const userLanguage = getUserDetails()?.locale?.language;
  const accountPrimaryLanguage = getAccountPrimaryLanguage();
  const getTranslatedDataForCustomObjects = useCallback(async () => {
    if (userLanguage !== accountPrimaryLanguage) {
      if (featureFlagConfigs.manageTranslation) {
        const translatedDataForCustomObjects = await manageTranslationService.getCombinedTranslationForLanguage({ languageCode: userLanguage });
        if (translatedDataForCustomObjects && !translatedDataForCustomObjects.error_type) {
          setTranslatedData(translatedDataForCustomObjects?.combined_translations);
        }
      }
    }
  }, [userLanguage, accountPrimaryLanguage]);

  useEffect(() => {
    getTranslatedDataForCustomObjects();
  }, [getTranslatedDataForCustomObjects]);

  const memoizedState = useMemo(() => {
    return {
      translatedData,
    };
  }, [translatedData]);

  const updateFuntions = useMemo(() => {
    return {
      setTranslatedData,
      getTranslatedDataForCustomObjects,
    };
  }, [setTranslatedData, getTranslatedDataForCustomObjects]);

  return (
    <ManageTranslationsStateContext.Provider value={memoizedState}>
      <ManageTranslationsSetterContext.Provider value={updateFuntions}>{children}</ManageTranslationsSetterContext.Provider>
    </ManageTranslationsStateContext.Provider>
  );
};

export const useManageTranslationsStateContext = () => {
  return useContext(ManageTranslationsStateContext);
};

export const useManageTranslationsSetterContext = () => {
  return useContext(ManageTranslationsSetterContext);
};
