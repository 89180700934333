import { format, getCurrentMonth, getLastMonth, getCurrentWeek, getLastWeek, getTomorrow, getToday, getYesterday } from './dateUtils';
import { ENTITY_FIELD_TYPE, LEGO_CONSTANTS } from 'constants/index';

export const getDate = dateString => {
  try {
    const dString = decodeURIComponent(dateString).toString();
    const date1 = new Date(dString);
    const date = new Date(date1.toDateString());
    return date;
  } catch (error) {
    console.info(error);
  }
};

export const getFilterForDate = (condition, value) => {
  const response = { condition, value };
  switch (condition) {
    case 'on': {
      response.condition = 'equals';
      response.value = format(getDate(response.value));
      break;
    }
    case 'after': {
      response.condition = encodeURIComponent('[gt]');
      response.value = format(getDate(response.value));
      break;
    }
    case 'before': {
      response.condition = encodeURIComponent('[lt]');
      response.value = format(getDate(response.value));
      break;
    }
    case 'isBetween': {
      response.condition = 'between';
      response.value = { from: format(getDate(response.value.from)), to: format(getDate(response.value.to)) };
      break;
    }
    case 'isEmpty':
      condition = 'isEmpty';
      break;
    case 'anytime':
      condition = 'isEmpty';
      break;
    case 'yesterday': {
      const yesterday = getYesterday();
      response.condition = 'equals';
      response.value = yesterday;
      break;
    }
    case 'today': {
      const today = getToday();
      response.condition = 'equals';
      response.value = today;
      break;
    }
    case 'tomorrow': {
      const tomorrow = getTomorrow();
      response.condition = 'equals';
      response.value = tomorrow;
      break;
    }
    case 'lastWeek': {
      const { from, to } = getLastWeek();
      response.condition = 'between';
      response.value = { from, to };
      break;
    }
    case 'thisWeek': {
      const { from, to } = getCurrentWeek();
      response.condition = 'between';
      response.value = { from, to };
      break;
    }
    case 'lastMonth': {
      const { from, to } = getLastMonth();
      response.condition = 'between';
      response.value = { from, to };
      break;
    }
    case 'thisMonth': {
      const { from, to } = getCurrentMonth();
      response.condition = 'between';
      response.value = { from, to };
      break;
    }
    default:
      return 'isEmpty';
  }
  return response;
};

export const getFilterValue = (type, filter) => {
  try {
    const { condition, value } = filter;
    if (ENTITY_FIELD_TYPE.CHECKBOX === type) {
      const newVal = condition === 'checked';
      return { condition, value: newVal };
    } else if (ENTITY_FIELD_TYPE.DATE === type) {
      return getFilterForDate(condition, value);
    }
    return { condition, value };
  } catch (error) {
    console.error(error);
  }
};

export const prepareFilterQuery = (schema, filters) => {
  try {
    let query = '';
    for (const [key, filter] of Object.entries(filters)) {
      let param = '&';
      const __filter = getFilterValue(schema[key].type, filter);

      if (!!__filter?.value && Array.isArray(__filter?.value)) {
        for (let i = 0; i < __filter.value.length; i++) {
          param += `${key}=${__filter.value[i].value}&`;
        }
        param = param.slice(0, -1);
      } else if (['equals', 'checked'].includes(__filter.condition)) {
        param += `${key}=${__filter.value}`;
      } else if (__filter.condition === 'notChecked') {
        param += `${key}=${__filter.value}&${key}=<null>`;
      } else if (schema[key].type === LEGO_CONSTANTS.DATE) {
        if (__filter.condition === 'between') {
          param += `${key}${encodeURIComponent('[gte]')}=${__filter.value.from}`;
          param += `&${key}${encodeURIComponent('[lte]')}=${__filter.value.to}`;
        } else if (__filter.condition === 'isEmpty') {
          param += `${key}=%00`;
        } else {
          param += `${key}${__filter.condition}=${__filter.value}`;
        }
      } else {
        param += `${key}[${__filter.condition}]=${__filter.value}`;
      }

      query += param;
    }
    return query.substring(1);
  } catch (error) {
    console.error(error);
  }
};
