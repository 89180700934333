import React, { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecordsPage } from 'hooks';
import { useRecordPageState, useNativeObjectsState, useFreshdeskContextState } from 'store';
import { ENTITY_FIELD_TYPE } from 'constants/index';
import { recordPageController } from 'controllers';
import nativeObjectConfigs from 'configs/nativeObjectConfigs';

const ForwardRefWidgetContext = React.createContext();
const ForwardRefWidgetUpdaterContext = React.createContext();

const ForwardRefProvider = props => {
  const { t } = useTranslation();
  const { getUserDetails, getConfigType } = useFreshdeskContextState();
  const configType = getConfigType();
  const language = getUserDetails()?.locale?.language;
  const timezone = getUserDetails()?.locale?.timezone;

  const nativeObjectData = useNativeObjectsState();
  const { record, entity, loading, associatedEntities: relatedEntities } = useRecordPageState();
  const [state, setState] = useState({
    isLoading: true,
    associations: {},
    recordsLookup: {},
  });
  const { getForwardAssociations, getAllNativeObjectAssociations } = useRecordsPage();

  const loadRecordsLookup = useCallback(
    async associations => {
      const nativeRecordIdLookup = Object.values(nativeObjectData.idLookup).reduce((map, schema) => {
        return { ...map, [schema.context]: [] };
      }, {});

      const relatedEntityLookupById = relatedEntities.reduce((map, relatedEntity) => {
        return { ...map, [relatedEntity.id]: relatedEntity };
      }, {});
      const lookup = {};
      Object.keys(associations ?? {}).forEach(relatedEntityId => {
        const relatedEntity = relatedEntityLookupById[relatedEntityId];
        const nativeFields = recordPageController.getNativeRelationshipFields(relatedEntity);
        const recordDetailList = associations[relatedEntityId].map(relatedRecord => {
          const nativeIdentifierToIdsLookup = recordPageController.getAssociatedNativeObjectIdsForRecord(relatedRecord.data, nativeObjectData, nativeFields);
          for (const [nativeIdentifer, nativeObjectIds] of Object.entries(nativeIdentifierToIdsLookup)) {
            nativeRecordIdLookup[nativeIdentifer] = [...(nativeRecordIdLookup[nativeIdentifer] ?? []), ...nativeObjectIds];
          }
          const { enrichedRecordData: details } = recordPageController.getEnrichRecordData(relatedEntity, relatedRecord, nativeObjectData, t, language, timezone);
          for (let i = 0; i < details.length; i++) {
            if (details[i].fieldType === ENTITY_FIELD_TYPE.RELATIONSHIP) {
              if (details[i].displayValue?.value) {
                relatedRecord.data[details[i].fieldName] = details[i].displayValue.value;
              }
            }
          }
          return relatedRecord;
        });
        lookup[relatedEntityId] = recordDetailList;
      });
      const nativeAssociations = await getAllNativeObjectAssociations(nativeRecordIdLookup);
      Object.keys(associations ?? {}).forEach(relatedEntityId => {
        const relatedEntity = relatedEntityLookupById[relatedEntityId];
        const nativeFields = recordPageController.getNativeRelationshipFields(relatedEntity);
        const nativeFieldNamesToContextLookup = nativeFields.reduce((map, field) => {
          const context = nativeObjectData?.idLookup[field.related_entity_id]?.context;
          return { ...map, [field.name]: context };
        }, {});
        associations[relatedEntityId].forEach(relatedRecord => {
          Object.keys(relatedRecord?.data ?? {}).forEach(fieldName => {
            if (nativeFieldNamesToContextLookup[fieldName]) {
              const value = relatedRecord.data[fieldName];
              const configs = nativeObjectConfigs[configType][nativeFieldNamesToContextLookup[fieldName]];
              relatedRecord.data[fieldName] =
                nativeAssociations?.[nativeFieldNamesToContextLookup[fieldName]]?.[value]?.[configs.defaultDisplayField] ?? relatedRecord.data[fieldName];
            }
          });
        });
      });
      return lookup;
    },
    [nativeObjectData, relatedEntities, getAllNativeObjectAssociations, t, language, timezone, configType]
  );

  const loadAssociations = useCallback(async () => {
    setState({ isLoading: true });
    if (loading || !nativeObjectData.loaded) return {};
    const response = await getForwardAssociations(entity, record);
    if (response.success) {
      const recordsLookup = await loadRecordsLookup(response.response);
      setState({ isLoading: false, associations: response.response, recordsLookup });
    } else {
      setState({ isLoading: false, associations: response.response, recordsLookup: {} });
    }
  }, [entity, getForwardAssociations, loadRecordsLookup, loading, nativeObjectData.loaded, record]);

  useEffect(() => {
    loadAssociations();
  }, [loadAssociations]);

  const updateFunctions = useMemo(() => {
    return {
      setState,
    };
  }, [setState]);

  return (
    <ForwardRefWidgetContext.Provider value={state}>
      <ForwardRefWidgetUpdaterContext.Provider value={updateFunctions}>{props.children}</ForwardRefWidgetUpdaterContext.Provider>
    </ForwardRefWidgetContext.Provider>
  );
};

const useForwardRefState = () => {
  const state = useContext(ForwardRefWidgetContext);
  if (!state) {
    throw new Error('useForwardRefState must be used within a ForwardRefProvider');
  }
  return state;
};

const useForwardRefUpdater = () => {
  const { setState } = useContext(ForwardRefWidgetUpdaterContext);
  if (!setState) {
    throw new Error('useForwardRefUpdater must be used within a ForwardRefProvider');
  }

  return { setState };
};

export { ForwardRefProvider, useForwardRefState, useForwardRefUpdater };
