import { useCallback, useMemo } from 'react';
import getSearchServiceImpl from '../services/search/searchServiceDiscovery';
import { SEARCH_SERVICE_TYPE, PERMISSION_TYPE, LEGO_CONSTANTS } from 'constants/index';
import { CommonUtils } from 'utils';
import { useFreshdeskContextState, useNativeObjectsState } from 'store';
import useMessageChannelAdapter from './use-message-channel-adapter';
import nativeObjectConfigs from 'configs/nativeObjectConfigs';

const useSearch = () => {
  const nativeObjectData = useNativeObjectsState();
  const { getRecordPermissions, getConfigType } = useFreshdeskContextState();

  const configType = getConfigType();
  const { search: searchMC } = useMessageChannelAdapter();
  const recordPermissions = useMemo(() => getRecordPermissions(), [getRecordPermissions]);

  const constructErrorObject = useCallback(message => {
    return { data: { message } };
  }, []);

  const isNative = useCallback(
    entityId => {
      return Object.keys(nativeObjectData.idLookup).includes(entityId?.toString());
    },
    [nativeObjectData]
  );

  const searchRecords = useCallback(
    async (term, entityId, searchField, searchServiceType, params = {}) => {
      try {
        if (!recordPermissions?.isPresent(PERMISSION_TYPE.READ)) throw constructErrorObject(LEGO_CONSTANTS.NO_RECORD_READ_PERMISSION);
        if (isNative(entityId)) {
          const config = nativeObjectConfigs[configType][searchServiceType];
          const nativeParams = { options: config.searchParams, ...params };
          return await searchMC(searchServiceType, { term, schema_id: entityId, page_size: LEGO_CONSTANTS.SEARCH_PAGE_SIZE, subParameters: nativeParams.options }, configType);
        } else {
          const service = getSearchServiceImpl(searchServiceType);
          const response = await service.search(
            {},
            { term: encodeURIComponent(term), schema_id: entityId, search_field: searchField, page_size: LEGO_CONSTANTS.SEARCH_PAGE_SIZE, context: searchServiceType, ...params }
          );
          return CommonUtils.buildResponse(true, response);
        }
      } catch (error) {
        console.error(error);
        return CommonUtils.buildErrorResponse(error);
      }
    },
    [recordPermissions, constructErrorObject, isNative, configType, searchMC]
  );

  const processSearchResponse = useCallback(
    (response, type, fieldName, searchServiceType) => {
      try {
        switch (searchServiceType) {
          case SEARCH_SERVICE_TYPE.ENTITY_RECORD: {
            const searchResponse = response?.records?.map(record => {
              const { display_id: displayId, data } = record;
              const response = {
                text: data[fieldName],
                value: type === LEGO_CONSTANTS.LOOKUP ? displayId : data[fieldName],
                context: SEARCH_SERVICE_TYPE.ENTITY_RECORD,
              };
              if (type === LEGO_CONSTANTS.LOOKUP) {
                response.meta = record;
              }
              return response;
            });
            return type !== LEGO_CONSTANTS.LOOKUP ? CommonUtils.getUniqueItemsByProperties(searchResponse, ['value']) : searchResponse;
          }
          default: {
            const config = nativeObjectConfigs[configType][searchServiceType];
            return response?.[config.searchResponseKey]?.map(item => {
              return {
                text: item[config.defaultDisplayField],
                value: item[config.ucrEnabledIdKey],
                context: searchServiceType,
                meta: item,
              };
            });
          }
        }
      } catch (e) {
        return [];
      }
    },
    [configType]
  );

  const executeForSearchType = useCallback(
    async (term, relatedEntityId, fieldName, searchServiceType, params = {}, type = LEGO_CONSTANTS.LOOKUP, processResponse = true) => {
      const response = await searchRecords(term, relatedEntityId, fieldName, searchServiceType, params);
      if (!response.success) return [];
      return processResponse ? processSearchResponse(response.response, type, fieldName, searchServiceType) : response.response;
    },
    [processSearchResponse, searchRecords]
  );

  const __getSearchServiceTypeForNativeSchema = useCallback(
    id => {
      let service = null;
      if (nativeObjectData?.idLookup[id]) {
        service = nativeObjectData.idLookup[id]?.context;
      }
      return service;
    },
    [nativeObjectData]
  );

  const getSearchServiceType = useCallback(
    relatedEntityId => {
      return isNative(relatedEntityId) ? __getSearchServiceTypeForNativeSchema(relatedEntityId, nativeObjectData) : SEARCH_SERVICE_TYPE.ENTITY_RECORD;
    },
    [__getSearchServiceTypeForNativeSchema, isNative, nativeObjectData]
  );

  const executeSearch = useCallback(
    async (context, params = {}) => {
      try {
        let { type, term, relatedEntityId, fieldName, entity, associatedEntities } = context;
        let toSearchFor = entity.id;
        let searchServiceType = SEARCH_SERVICE_TYPE.ENTITY_RECORD;
        if (type === LEGO_CONSTANTS.LOOKUP) {
          searchServiceType = getSearchServiceType(relatedEntityId, nativeObjectData);
          const relatedEntity = associatedEntities.filter(e => e.id === relatedEntityId)?.[0];
          fieldName = CommonUtils.getPrimaryField(relatedEntity)?.name;
          toSearchFor = relatedEntityId;
        }
        return executeForSearchType(term, toSearchFor, fieldName, searchServiceType, params, type);
      } catch (e) {
        return [];
      }
    },
    [executeForSearchType, getSearchServiceType, nativeObjectData]
  );

  return useMemo(() => {
    return {
      executeSearch,
      executeForSearchType,
      getSearchServiceType,
    };
  }, [executeSearch, executeForSearchType, getSearchServiceType]);
};

export default useSearch;
